import { defineStore } from 'pinia'

import { useOnlineCreditDecisionStore } from './ocd/onlineCreditDecisionStore'
import { useCreditCheckStore } from './ocd/creditCheckStore'
import { useFinanceApplicationStore } from './ocd/financeApplicationStore'
import { useFormFactoryStore } from './ocd/formFactoryStore'
import { IStep } from '~/types/steps'

export enum EFinanceEligibilitySteps {
  Login = 'Login',
  Showroom = 'Showroom',
  CheckFinance = 'Check finance eligibility',
  ApplyForFinance = 'Apply for finance',
  ThankYou = 'Thank you'
}

export const steps: IStep[] = [
  {
    label: EFinanceEligibilitySteps.Showroom,
    title: 'Check finance eligibility'
  },
  {
    label: EFinanceEligibilitySteps.CheckFinance,
    title: ''
  },
  {
    label: EFinanceEligibilitySteps.ApplyForFinance,
    title: ''
  },
  {
    label: EFinanceEligibilitySteps.ThankYou,
    title: ''
  }
]

export const useFinanceEligibilityStore = defineStore('financeEligibility', {
  hasNavigation: true,

  getters: {
    getSteps(): IStep[] {
      return steps
    },
    hasError(): boolean {
      const ocdStore = useOnlineCreditDecisionStore()
      const creditCheckStore = useCreditCheckStore()
      const finnaceApplicationStore = useFinanceApplicationStore()
      const ocdFormFactoryStore = useFormFactoryStore()

      return (
        ocdStore.hasError ||
        creditCheckStore.hasError ||
        finnaceApplicationStore.hasError ||
        ocdFormFactoryStore.hasError
      )
    }
  },
  actions: {
    reset() {
      const ocdStore = useOnlineCreditDecisionStore()

      this.$reset()
      ocdStore.reset()
    }
  }
})
